
import {
  computed, defineComponent, onMounted, PropType, reactive, watch,
} from 'vue';
import VueI18n from 'vue-i18n';
import { debounce } from 'lodash';
import { AtomSort, AtomType, ICatalogueAtomFilters } from '@/domains/atom';
import { IBreadcrumb } from '@/domains/common';
import UpButton from '@/components/ui/UpButton.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import InputSearch from '@/components/ui/InputSearch.vue';
import SortOrderProgramsMenu from '@/components/catalogue/SortOrderProgramsMenu.vue';
import CatalogueSidebar from '@/components/catalogue/CatalogueSidebar/CatalogueSidebar.vue';
import useCatalogueSidebar from '@/components/catalogue/composables/useCatalogueSidebar';
import { useCatalogueAtomsPageable } from '@/domains/atom/composables/useCatalogueAtomsPageable';
import * as CatalogueService from '@/services/api/catalogue';
import InfinityScroll from '@/components/ui/InfinityScroll.vue';
import EmptyAtomsFilteredList from '@/components/catalogue/EmptyAtomsFilteredList.vue';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';
import useCatalogueAtomCard from '@/components/catalogue/composables/useCatalogueAtomCard';
import LocaleMessage = VueI18n.LocaleMessage;

type ISidebarFilters = Pick<ICatalogueAtomFilters, 'durations' | 'categories' | 'providers'>

export default defineComponent({
  name: 'LearningCatalogueAtoms',
  components: {
    UpButton,
    TTBackButton,
    InputSearch,
    SortOrderProgramsMenu,
    InfinityScroll,
    CatalogueSidebar,
    EmptyAtomsFilteredList,
  },
  props: {
    title: {
      required: true,
      type: String as PropType<string | LocaleMessage>,
    },
    atomTypes: {
      type: Array as PropType<AtomType[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { getAtomPreviewUrl } = useCatalogueAtomCard();
    const breadcrumbs = computed<IBreadcrumb[]>(() => {
      return [
        {
          text: t('LearningCatalogue.breadcrumbs.myLearning'),
          to: {
            name: Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-learning',
        },
        {
          text: t('LearningCatalogue.breadcrumbs.catalogue'),
          to: {
            name: Names.R_APP_LEARNING_CATALOGUE,
          },
          'data-test-label': 'learning-catalogue',
        },
        {
          text: props.title,
          disabled: true,
          'data-test-label': 'atom-title',
        },
      ];
    });
    const filters = reactive<ICatalogueAtomFilters>({
      search: '',
      providers: [],
      categories: [],
      durations: [],
      sort: AtomSort.UPDATED_AT_DESC,
      atomNames: props.atomTypes,
    });
    const {
      contentProviders,
      categories,
      fetch: fetchSidebarData,
    } = useCatalogueSidebar();
    const page = useCatalogueAtomsPageable({
      limit: 20,
      fetchAtomsMethod: CatalogueService.userTracksGet,
    });

    const onFiltersChanged = debounce(async () => {
      page.resetData();
      await page.fetchAtoms(filters);
    }, 300);

    watch(filters, () => {
      onFiltersChanged();
    });

    onMounted(async () => {
      fetchSidebarData();
      await page.fetchAtoms(filters);
    });

    return {
      filters,
      categories,
      breadcrumbs,
      contentProviders,
      items: page.items,
      limit: page.limit,
      total: page.total,
      isEmpty: page.isEmpty,
      isLoading: page.isLoading,
      currentPage: page.currentPage,
      onSidebarFiltersChanged(sidebarFilters: ISidebarFilters) {
        filters.durations = sidebarFilters.durations;
        filters.categories = sidebarFilters.categories;
        filters.providers = sidebarFilters.providers;
        onFiltersChanged();
      },
      getAtomPreviewUrl,
      onAtomsNextPage() {
        page.fetchNextPage(filters);
      },
    };
  },
});
