import { computed } from 'vue';
import * as CatalogueService from '@/services/api/catalogue';
import { MY_COMPANY_PROVIDER } from '@/domains/content-provider';
import useFetch from '@/composables/useFetch';

export default function useCatalogueSidebar() {
  const {
    data: providersData,
    isLoading: isProvidersLoading,
    error: providersError,
    fetch: fetchProviders,
  } = useFetch(() => CatalogueService.contentProvidersGet());

  const {
    data: categoriesData,
    isLoading: isCategoriesLoading,
    error: categoriesError,
    fetch: fetchCategories,
  } = useFetch(() => CatalogueService.atomCategoriesGet());

  return {
    fetch() {
      Promise.all([
        fetchProviders(),
        fetchCategories(),
      ]);
    },
    isError: computed(() => providersError.value || categoriesError.value),
    isLoading: computed(() => isProvidersLoading.value && isCategoriesLoading.value),
    contentProviders: computed(() => {
      if (providersData.value?.providers) {
        return [MY_COMPANY_PROVIDER, ...providersData.value?.providers];
      }
      return [];
    }),
    categories: computed(() => {
      return categoriesData.value?.categories || [];
    }),
  };
}
