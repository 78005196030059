
import { computed, defineComponent } from 'vue';
import { useI18n } from '@/plugins/vue-i18n';
import LearningCatalogueAtoms from '@/components/catalogue/LearningCatalogueAtoms.vue';
import { AtomType } from '@/domains/atom';
import useCatalogueAssignment from '@/components/catalogue/composables/useCatalogueAssignment';
import { CatalogueAtomCard } from '@/components/catalogue/CatalogueAtomCard';
import useCatalogueAtomCard from '@/components/catalogue/composables/useCatalogueAtomCard';

export default defineComponent({
  name: 'LearningCatalogueLessons',
  components: { LearningCatalogueAtoms, CatalogueAtomCard },
  setup() {
    const { t } = useI18n();
    const title = t('LearningCatalogue.MicroEducation.title');
    const atomTypes = computed(() => [AtomType.MULTICONTENT]);
    const { onAssignAtom } = useCatalogueAssignment({ isRecommendations: false });
    const { getAtomPreviewUrl } = useCatalogueAtomCard();

    return {
      title, atomTypes, onAssignAtom, getAtomPreviewUrl,
    };
  },
});
