
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import { ButtonState } from '@/components/tracks/PlusButton/types';

export default defineComponent({
  name: 'PlusButton',

  props: {
    initialState: {
      type: String,
      default: (() => ButtonState.DEFAULT),
    },
  },

  setup(props, { emit }) {
    const state = ref(props.initialState);
    watch(() => props.initialState, () => { state.value = props.initialState; }, { immediate: true });

    const isDefaultState = computed(() => state.value === ButtonState.DEFAULT);
    const isLoadingState = computed(() => state.value === ButtonState.LOADING);
    const isCheckedState = computed(() => state.value === ButtonState.CHECKED);

    const onClickHandler = () => {
      if (isCheckedState.value) return false;

      return new Promise((resolve) => {
        emit('clicked', resolve);
        state.value = ButtonState.LOADING;
      })
        .then(() => {
          state.value = ButtonState.CHECKED;
        })
        .catch((e: unknown) => {
          console.error(e);
          state.value = ButtonState.DEFAULT;
        });
    };

    return {
      isDefaultState,
      isLoadingState,
      isCheckedState,
      onClickHandler,
    };
  },
});
