import { ref } from 'vue';

type IFetchFn<T, P = void> = (arg: P) => Promise<T>

export default function useFetch<E = Error, T = unknown, P = void>(
  fn: IFetchFn<T, P>,
) {
  const isLoading = ref(false);
  const error = ref<E | null>();
  const data = ref<T | null>();
  const fetch = async (arg: P) => {
    isLoading.value = true;
    error.value = null;
    try {
      data.value = await fn(arg);
      return true;
    } catch (e) {
      error.value = e as E;
      data.value = null;
    } finally {
      isLoading.value = false;
    }
    return false;
  };

  return {
    data,
    isLoading,
    error,
    fetch,
  };
}
